import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Form} from "antd";
import Section from "../../../components/layout/Section";
import ScheduleForm from "./form";
import ScheduleCalender from "./calender/index";
import {Auth} from "../../../utils/auth";
import {
    addSchedule,
    checkSchedule,
    deleteSchedule,
    fetchSchedule,
    getPdfPatientPacket,
    sendRepEmail,
    sendSurgeonEmail,
    sendRepEmailId,
    fetchSchedules,
    resetScheduleForm,
    updateSchedule,
    updateOneSchedule,
    getHospitalInfoInPDF,
    cancelTheSchedule, fetchFilteredSchedules,
    doctorAvailability,
    getDoctorAvailabilities,
} from "../../../redux/schedule-management/schedules/actionCreator";
import {
    fetchProcedures as fetchUtilitiesProcedures,
    fetchProcedure as fetchUtilitiesProcedure,
    fetchSurgeons as fetchUtilitiesSurgeons,
    fetchPatients as fetchUtilitiesPatients,
    fetchPatient as fetchUtilitiesPatient,
    fetchFacilities as fetchUtilitiesFacilities,
    fetchCoSurgeons as fetchUtilitiesCoSurgeons, fetchFilteredPatients
} from "../../../redux/utilities/actionCreator";
import SurgeonFilter from "./filter";

const ScheduleData = () => {
    const schedules = useSelector(state => state.smSchedules.schedules);
    const availabilities = useSelector(state => state.smSchedules.availabilities);
    const serverFilteredSchedules = useSelector(state => state.smSchedules.serverFilteredSchedules);
    const schedule = useSelector(state => state.smSchedules.schedule);
    const surgeon = useSelector(state => state.pmSurgeons.surgeon);
    const utilitiesProcedures = useSelector(state => state.utilities.procedures);
    const utilitiesSurgeons = useSelector(state => state.utilities.surgeons);
    const utilitiesCoSurgeons = useSelector(state => state.utilities.coSurgeons);
    const utilitiesPatients = useSelector(state => state.utilities.patients);
    const utilitiesPatient = useSelector(state => state.utilities.patient);
    const utilitiesFacilities = useSelector(state => state.utilities.facilities);
    const emails = useSelector(state => state.utilities.emails);
    const cancelEmails = useSelector(state => state.utilities.cancelEmails);
    const procedure = useSelector(state => state.pmProcedures.procedure);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [filter] = Form.useForm();
    const getUsers = Auth.getUserInfo();
    const initialFilters = {surgeonId: ""};
    const [filters, setFilters] = useState({
        surgeonId: "",
        active: "",
        scheduleStatus: "",
        daysValue: [],
        coSurgeonId: "",
        procedure: [],
        facilityId: "",
        patientId: ""
    });
    const [filterState, setFilterState] = useState({filterVisible: false});
    const [getTheSurgeon, setTheSurgeon] = useState({})
    const [state, setState] = useState({
        visible: false,
        modalType: 'primary',
        colorModal: false,
        uploading: false,
    });
    const [getSchedule, setSchedule] = useState({
        visible: false,
        modalType: 'secondary',
        colorModal: false,
        uploading: false,
    })

    const [modalVisible, setModalVisible] = useState(false)
    const [scheduleVisible, setScheduleVisible] = useState(false)
    const [showPrint, setShowPrint] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            if (
                filters?.surgeonId
                || filters?.active
                || filters?.scheduleStatus
                || filters?.daysValue?.length
                || filters?.coSurgeonId
                || filters?.procedure?.length
                || filters?.facilityId
                || filters?.patientId
            ) await dispatch(fetchFilteredSchedules(filters))
        };
        fetchData().then(r => {});

    },[filters])

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchUtilitiesProcedures())
            await dispatch(fetchUtilitiesSurgeons())
            await dispatch(fetchUtilitiesCoSurgeons())
            // await dispatch(fetchUtilitiesPatients())
            await dispatch(fetchUtilitiesFacilities())
            await dispatch(getDoctorAvailabilities())
            // await dispatch(fetchSchedules());
            // await dispatch(fetchEmails());
            // await dispatch(fetchCancelEmails());
        };
        fetchData().then(r => null);
    }, [dispatch]);

    const getScheduleInfo = (id) => dispatch(fetchSchedule(id, showModalEdit));
    const viewScheduleInfo = (id) => dispatch(fetchSchedule(id, showScheduleModal));
    const getPdfOrderInfo = (pdfOrderInfo, action) => dispatch(getHospitalInfoInPDF(pdfOrderInfo, action));
    const getPatientPacketsPDF = (pdfPackets, action) => dispatch(getPdfPatientPacket(pdfPackets, action));
    const sendRepresentativeEmail = (id, email) => dispatch(sendRepEmail(id, email));
    const sendInvitationEmail = (id, email) => dispatch(sendSurgeonEmail(id, email));
    const sendRepresentativeEmailId = (email) => dispatch(sendRepEmailId(email));
    const cancelSchedule = (id) => dispatch(cancelTheSchedule(id));

    const showModalEdit = async () => {
        await setSchedule({
            ...getSchedule,
            visible: false,
            modalType: "secondary"
        });
        await setState({
            ...state,
            visible: true,
            modalType: "primary",
        });
        setScheduleVisible(false)
        form.resetFields()
    };

    const handleOk = () => {
        dispatch(resetScheduleForm())
        setState({
            ...state,
            visible: false,
            colorModal: false,
        });
        setModalVisible(false)
        setScheduleVisible(false)
        form.resetFields()
    };

    const showScheduleModal = async () => {
        await setSchedule({
            ...getSchedule,
            visible: true,
            modalType: "secondary"
        });
        await setModalVisible(false)
        await setShowPrint(false)
        await form.resetFields();
    }

    const showModal = async (type, date = null) => {
        await dispatch(resetScheduleForm())
        await form.resetFields()
        await setState({
            ...state,
            visible: true,
            modalType: type,
        });
        setScheduleVisible(false)
        await form.resetFields()
        if (date) {
            const myDate = new Date(date);
            const myDateYMD = new Date(myDate.getTime() - (myDate.getTimezoneOffset() * 60000))
                .toISOString()
                .split("T")[0];

            await form.setFieldsValue({
                ["date"]: myDateYMD
            })
        }
    };

    const viewScheduleOk = async () => {
        await dispatch(resetScheduleForm());
        setSchedule({
            ...getSchedule,
            visible: false,
            colorModal: false,
        });
        setScheduleVisible(false)
    }

    return (
        <Section>
            <ScheduleCalender
                showModal={showModal}
                state={state}
                setState={setState}
                getSchedule={getSchedule}
                setSchedule={setSchedule}
                modalVisible={modalVisible}
                scheduleVisible={scheduleVisible}
                getUsers={getUsers}
                filterState={filterState}
                getFilteredSchedules={serverFilteredSchedules}
                setFilterState={setFilterState}
                surgeon={filters.surgeonId ? getTheSurgeon : surgeon}
                viewScheduleInfo={viewScheduleInfo}
                doctorAvailability={doctorAvailability}
                getDoctorAvailabilities={getDoctorAvailabilities}
                availabilities={availabilities}
            />
            <ScheduleForm
                state={state}
                setState={setState}
                showPrint={showPrint}
                setShowPrint={setShowPrint}
                getSchedule={getSchedule}
                setSchedule={setSchedule}
                setModalVisible={setModalVisible}
                setScheduleVisible={setScheduleVisible}
                form={form}
                getUsers={getUsers}
                addSchedule={addSchedule}
                checkSchedule={checkSchedule}
                updateSchedule={updateSchedule}
                updateOneSchedule={(event) => dispatch(updateOneSchedule(event))}
                deleteSchedule={deleteSchedule}
                viewScheduleOk={viewScheduleOk}
                handleOk={handleOk}
                schedules={schedules}
                patients={utilitiesPatients}
                fetchFilteredPatients={fetchFilteredPatients}
                surgeons={utilitiesSurgeons}
                coSurgeons={utilitiesCoSurgeons}
                patient={utilitiesPatient}
                schedule={schedule}
                facilities={utilitiesFacilities}
                procedures={utilitiesProcedures}
                procedure={procedure}
                emails={emails}
                cancelEmails={cancelEmails}
                getPatientInfo={fetchUtilitiesPatient}
                getProcedureInfo={fetchUtilitiesProcedure}
                getScheduleInfo={getScheduleInfo}
                getPdfOrderInfo={getPdfOrderInfo}
                getPatientPacketsPDF={getPatientPacketsPDF}
                sendInvitationEmail={sendInvitationEmail}
                sendRepresentativeEmail={sendRepresentativeEmail}
                sendRepresentativeEmailId={sendRepresentativeEmailId}
                cancelSchedule={cancelSchedule}
            />
            <SurgeonFilter
                procedures={utilitiesProcedures}
                facilities={utilitiesFacilities}
                patients={utilitiesPatients}
                fetchFilteredPatients={fetchFilteredPatients}
                filter={filter}
                filterState={filterState}
                setFilterState={setFilterState}
                getTheSurgeon={getTheSurgeon}
                setTheSurgeon={setTheSurgeon}
                initialFilters={initialFilters}
                filters={filters}
                setFilters={setFilters}
                surgeons={utilitiesSurgeons}
                coSurgeons={utilitiesCoSurgeons}
            />
        </Section>
    )
}

export default ScheduleData;
